$(document).ready(function () {

    var $form = $(".form-validate");

    if ($form.length) {
        $form.each(function(){
            var $submit = $('.submit', this);
            var $formSending = $('.form-sending', this);
            var $formMessageSuccess = $('.form-message-success', this);
            var $formMessageError = $('.form-message-error', this);

            $(this).validate({
                onfocusout: false,
                onkeyup: false,
                onclick: false,
                errorElement: "div",
                errorClass: 'errorField',
                submitHandler: function(form) {
                   $(form).ajaxSubmit({
                       beforeSubmit: function(formData, jqForm, options) {
                           $formSending.show();
                           $submit.attr('disabled', true);
                       },
                       success: function(responseText, statusText, xhr, $form) {
                           var response = responseText;

                           if (response.status == 1) {
                               console.log(11);
                               form.reset();
                               $formMessageError.addClass('hide');
                               $formMessageSuccess.removeClass('hide');
                           } else {
                               $formMessageError.removeClass('hide');
                               $formMessageSuccess.addClass('hide');
                           }

                           $formSending.hide();
                           $submit.removeAttr('disabled');
                       }
                   });
                }
            });
        });
    }


///////////////////////////////////////////////////////
////////////////////////  MAP  ////////////////////////
///////////////////////////////////////////////////////

var mapEl = 'myMap';

function init() {
  var mapOptions = {
    zoom: 15,
    center: new google.maps.LatLng(-31.3748635, -64.2288671),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    scrollwheel: false,
    zoomControl: true,
    styles: [{
      stylers: [{
        saturation: -100
      }]
    }]
  };
  var myMap = new google.maps.Map(document.getElementById(mapEl), mapOptions);
  var marker = new google.maps.Marker({
    position    : new google.maps.LatLng(-31.3748635, -64.2288671),
    icon        : '../images/map-marker.png',
    map         : myMap
  });
  marker.setMap(myMap);
 google.maps.event.addListener(marker, 'click', function() {
  window.open('https://goo.gl/maps/ojZm2anLPwk', '_blank');
  });

}

jQuery(document).ready(function($){

    if ($('#' + mapEl).length) {
        google.maps.event.addDomListener(window, 'load', init);
    }
});

///////////////////////////////////////////////////////
////////////////////////  WOW  ////////////////////////
///////////////////////////////////////////////////////

    new WOW().init();

///////////////////////////////////////////////////////
////////////////////////  PARALAX  /////////////////////
///////////////////////////////////////////////////////

    $.stellar();


///////////////////////////////////////////////////////
////////////////////////  TABS  //////////////////////
///////////////////////////////////////////////////////


$(document).ready(function() {
    $("div.bhoechie-tab-menu>div.list-group>a").click(function(e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
        $("div.bhoechie-tab>div.bhoechie-tab-content").eq(index).addClass("active");
    });
});



///////////////////////////////////////////////////////
///////////////////////  NAV  /////////////////////////
///////////////////////////////////////////////////////


  $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= 120) {
           $(".navbar").addClass("navbar_active");
      } else {
          $(".navbar").removeClass("navbar_active");
      }
  });

  ///////////////////////////////////////////////////////
  ///////////////  SCROLLING NAV  ///////////////////////
  ///////////////////////////////////////////////////////


  //jQuery to collapse the navbar on scroll
  $(window).scroll(function() {
      if ($(".navbar").offset().top > 50) {
          $(".navbar-fixed-top").addClass("top-nav-collapse");
      } else {
          $(".navbar-fixed-top").removeClass("top-nav-collapse");
      }
  });

  //jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });


///////////////////////////////////////////////////////
///////////////  TABS HEIGHT  ///////////////////////
///////////////////////////////////////////////////////

var tabsMenuHeight = $('.bhoechie-tab-menu').outerHeight();

function setTabsHeight () {
  $('.bhoechie-tab').css('min-height', tabsMenuHeight);
};

setTabsHeight();


$(window).resize(function(){
  setTabsHeight();
});


///////////////////////////////////////////////////////
///////////////  particles.js config //////////////////
///////////////////////////////////////////////////////


if($('#particles-js').length){
  particlesJS("particles-js", {
  "particles": {
    "number": {
      "value": 100,
      "density": {
        "enable": true,
        "value_area":1000
      }
    },
    "color": {
      // "value": ["#aa73ff", "#f8c210", "#83d238", "#33b1f8"]
      "value": "#3ae8e0"
    },

    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#fff"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.4,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 4,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 120,
      "color": "#fff",
      "opacity": 0.2,
      "width": 1
    },
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "grab"
      },
      "onclick": {
        "enable": false
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 140,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
});

}

///////////////////////////////////////////////////////
////////////////////////  OWL  ////////////////////////
///////////////////////////////////////////////////////


    $('#owl-brands').owlCarousel({
      autoplay:true,
      autoplayTimeout: 5000,
      loop:true,
      responsiveClass:true,
      responsive: {
         0 : {
            items: 1
        },
        480 : {
            items: 3
        },
        768 : {
            items: 3
        },
        1200 : {
            items: 4
        }
      },
       dots: false,
       nav: true,
       navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    });




});
